<script>
import { h, ref } from 'vue'
import enroll from './components/enroll.vue'
import { ElTabs, ElTabPane } from 'element-plus'
export default {
  setup () {
    const enrollCom = ref(null)
    const tabs = {
      activeName: 'enroll',
      tabs: [
        { label: '订单管理', name: 'enroll', component: enroll, ref: enrollCom }
      ]
    }

    return {
      tabs
    }
  },
  render () {
    return h(
      ElTabs, {
        modelValue: this.tabs.activeName
      }, () => this.tabs.tabs.map(item => {
        return h(ElTabPane, {
          name: item.name,
          label: item.label
        }, () => [
          h(item.component, {
            ref: item.ref || ''
          })
        ])
      })
    )
  }
}
</script>
<style lang="scss" scoped>
</style>
