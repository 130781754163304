<template>
  <!-- table -->
  <xm-flex>
    <xm-flex-item>
      <xm-search-box>
        <el-select v-model="search.payType" placeholder="请选择支付状态" size="small">
          <el-option v-for="payStatus in selector.payStatusList" :key="payStatus.id" :label="payStatus.name" :value="payStatus.payType"></el-option>
        </el-select>
        <el-select v-model="search.flag" placeholder="请选择处理状态" size="small">
          <el-option v-for="flagStatus in selector.flagStatusList" :key="flagStatus.id" :label="flagStatus.name" :value="flagStatus.flag"></el-option>
        </el-select>
        <el-date-picker
          v-model="selector.period"
          type="daterange"
          unlink-panels
          range-separator="To"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          :shortcuts="shortcuts"
          size="small"
          @change="changePeriod"
        />
        <el-input v-model="search.searchText" size="small" maxlength="128" placeholder="请输入用户名/手机号" clearable></el-input>
        <el-button size="small" type="primary" @click="searchData">搜索</el-button>
      </xm-search-box>
    </xm-flex-item>
    <xm-flex-item><xm-total-count>{{`订单列表(${totalCount}条)`}}<el-button style="margin-left: 20px;" size="small" type="text" @click="download">下载报表</el-button></xm-total-count></xm-flex-item>
    <xm-flex-item :flex="1">
      <xm-table-plus :query-data="queryData" ref="table">
        <el-table-column prop="id" label="ID" width="80"></el-table-column>
        <el-table-column prop="name" label="用户名" width="120"></el-table-column>
        <el-table-column prop="phone" label="手机号" width="120"></el-table-column>
        <el-table-column prop="payTypeName" label="支付状态" width="80"></el-table-column>
        <el-table-column prop="flagName" label="处理状态" width="80"></el-table-column>
        <el-table-column prop="addTime" label="时间" width="180"></el-table-column>
        <el-table-column label="金额" width="80">
          <template #default="scope">
            ¥{{scope.row.fee}}
          </template>
        </el-table-column>
        <el-table-column label="折扣金额" width="80">
          <template #default="scope">
            ¥{{scope.row.offFee}}
          </template>
        </el-table-column>
        <el-table-column label="退款" width="80">
          <template #default="scope">
            ¥{{scope.row.refundFee}}
          </template>
        </el-table-column>
        <el-table-column label="独立支付" width="80">
          <template #default="scope">
            {{scope.row.isThirdparty ? '是' : '否'}}
          </template>
        </el-table-column>
        <el-table-column prop="points" label="积分" width="80"></el-table-column>
        <el-table-column prop="groupBuyEnrollId" label="团id" width="80"></el-table-column>
        <el-table-column label="拼团" width="120">
          <template #default="scope">
            {{scope.row.groupBuy ? `${scope.row.groupBuy.memberCount}人团¥${scope.row.groupBuy.price}` : '/'}}
          </template>
        </el-table-column>
        <el-table-column label="团长" width="80">
          <template #default="scope">
            {{scope.row.isGroupMaster ? '是' : '否'}}
          </template>
        </el-table-column>
        <el-table-column label="商品" width="200">
          <template #default="scope">
            <div v-for="(course, index1) in scope.row.courseList" :key="index1">
              《{{course.name}}》<span v-for="(attribute, index2) in course.attributeList" :key="index2">（{{attribute.name}}）</span> x{{course.count}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="快递地址" width="200">
          <template #default="scope">
            <div v-if="scope.row.enrollAddress">
              <div>{{scope.row.enrollAddress.name}}</div>
              <div>{{scope.row.enrollAddress.phone}}</div>
              <div>{{scope.row.enrollAddress.province}}{{scope.row.enrollAddress.city}}{{scope.row.enrollAddress.district}}{{scope.row.enrollAddress.address}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="表单" width="200">
          <template #default="scope">
            <div v-for="(enrollCourseSheet, index1) in scope.row.enrollCourseSheetList" :key="index1">
              《{{enrollCourseSheet.courseName}}》：
              <div v-for="(enrollCourseSheetItem, index2) in enrollCourseSheet.enrollCourseSheetItemList" :key="index2">{{enrollCourseSheetItem.sheetItem.name}}：{{enrollCourseSheetItem.sheetItemContent}}</div>
            </div>
          </template>
        </el-table-column>
      </xm-table-plus>
    </xm-flex-item>
  </xm-flex>
  <!-- drawer -->
  <el-drawer v-model="drawer.visible" title="图文详情" :size="900" destroy-on-close>
    <xm-flex>
      <xm-flex-item :flex="1">
        <desc-group-editor :desc-group-id="drawer.id" />
      </xm-flex-item>
    </xm-flex>
  </el-drawer>
</template>
<script>
import { ElButton, ElSelect, ElOption, ElTableColumn, ElInput, ElDrawer, ElDatePicker } from 'element-plus'
import axios from '@/common/utils/axios'
import { onMounted, reactive, ref } from 'vue'
function table () {
  const search = reactive({
    payType: -1,
    flag: -1,
    period: [new Date(), new Date()],
    searchText: ''
  })
  const selector = reactive({
    payStatusList: [{
      id: -1,
      name: '不限支付状态',
      payType: -1
    }, {
      id: 0,
      name: '未支付',
      payType: 0
    }, {
      id: 1,
      name: '在线支付',
      payType: 1
    }, {
      id: 2,
      name: '积分支付',
      payType: 2
    }],
    flagStatusList: [{
      id: -1,
      name: '不限处理状态',
      flag: -1
    }, {
      id: 0,
      name: '待处理',
      flag: 0
    }, {
      id: 2,
      name: '已处理',
      flag: 2
    }, {
      id: 3,
      name: '作废',
      flag: 3
    }]
  })
  const shortcuts = [{
    text: '今天',
    value: [new Date(), new Date()]
  }, {
    text: '今年',
    value: () => {
      const end = new Date()
      const start = new Date(new Date().getFullYear(), 0)
      return [start, end]
    }
  }, {
    text: '最近6个月',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setMonth(start.getMonth() - 6)
      return [start, end]
    }
  }]
  /**
     * 表单信息
     */
  const table = ref(null)
  const totalCount = ref(0)
  /**
     * 查询表单列表
     */
  const queryData = (page, pageSize) => {
    return new Promise((resolve) => {
      axios('/v1/enroll/list', {
        payType: search.payType,
        flag: search.flag,
        page,
        pageSize,
        startTime: new Date(search.period[0]).getTime(),
        endTime: new Date(search.period[1]).getTime(),
        searchText: search.searchText
      })
        .then(res => {
          totalCount.value = res.totalCount
          table.value.judgePosition(res.enrollList, res.totalCount)
          resolve({
            list: res.enrollList,
            totalCount: res.totalCount
          })
        }).catch(_ => { })
    })
  }
  const changePeriod = (e) => {
    search.period = e
  }
  const download = () => {
    return new Promise((resolve) => {
      axios('/v1/enroll/list/download', {
        payType: search.payType,
        flag: search.flag,
        startTime: new Date(search.period[0]).getTime(),
        endTime: new Date(search.period[1]).getTime(),
        searchText: search.searchText
      })
        .then(res => {
          window.open(res.fileUrl)
        }).catch(_ => { })
    })
  }
  /**
   * 搜索模板
   */
  const searchData = () => {
    table.value.reload()
  }

  onMounted(async () => {
    await queryData(1, 10)
    table.value.reload()
  })

  return {
    search,
    selector,
    table,
    queryData,
    download,
    totalCount,
    searchData,
    shortcuts,
    changePeriod
  }
}

function drawer () {
  const drawer = reactive({
    id: null,
    visible: false
  })

  return {
    drawer
  }
}

function operation (t, dr) {
  /**
     * 编辑模板
     */
  const showDescGroup = async (id, cid) => {
    dr.drawer.id = id
    dr.drawer.visible = true
  }
  return {
    showDescGroup
  }
}

export default {
  components: { ElButton, ElSelect, ElOption, ElTableColumn, ElInput, ElDrawer, ElDatePicker },

  setup () {
    const t = table()
    const dr = drawer()
    const o = operation(t, dr)
    return {
      ...t,
      ...dr,
      ...o
    }
  }
}
</script>
<style lang="scss" scoped>
.button-group {
  text-align: right;
}
.el-dropdown .el-button {
  margin-left: 10px;
}
.drawer-button {
  padding: 10px 0;
}
</style>
